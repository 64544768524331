import React from "react";
import Slider from "react-slick";
import Reveal from "react-reveal";
import { RichText } from "prismic-reactjs";

const SubProgram = ({ subProgram, themeColor })  => {
    const { fields } = subProgram;
    const renderSubprogram = (sub, index) => {
      const gallerySettings = {
        dots: true,
        infinite: true,
        arrows: false
      };
      const title = sub.subprogram?.body.find(x => x.type === "title_slice")?.primary?.title[0]?.text;
      const description = sub.subprogram?.body.find(x => x.type === "description_slice")?.primary?.description;
      const gallery = sub.subprogram?.body.find(x => x.type === "gallery_slice")?.fields;
      const video = sub.subprogram?.body.find(x => x.type === "video_slice")?.primary?.video_embed;
      const html = sub.subprogram?.body.find(x => x.type === "html_slice")?.primary?.custom_html;
      const step_gallery = sub.subprogram?.body.find(x => x.type === "step_gallery_slice")?.fields;
      const steps = sub.subprogram?.body.find(x => x.type === "steps_slice")?.fields;
      const animatedFirst = index % 2 == 0 ? 'fadeInLeft' : 'fadeInRight';
      const animatedSecond = index % 2 == 0 ? 'fadeInRight' : 'fadeInLeft';
      
      const renderSteps = () => steps.map((step, i) => {
        return (
          <div className="subprogram-body-steps" key={i}>
            <div className="subprogram-body-steps-title-container">
              <div className="subprogram-body-steps-number">{i + 1}.</div>
              <div className="subprogram-body-steps-title">{step.step_title[0].text}</div>
            </div>  
            <div className="subprogram-body-steps-gallery-container">
              <div className={`subprogram-body-steps-gallery grid-${i}`}>
                <img src={step.step_image?.url} />
                <div className="overlay-bg"></div>
                <div className="padding">
                  <div></div>
                </div>
                  {/* <div className="subprogram-body-steps-gallery-inner">
                      <div className="subprogram-body-steps-gallery-title">{step.step_title[0].text}</div>
                      <div className="subprogram-body-steps-gallery-desc">{step.desc}</div>
                  </div> */}
                </div>
            </div>
          </div>
        )
      });

      const renderStepGallery = () => step_gallery.map(step => {
        const galleryTitle = step.gallery_title ? step.gallery_title[0] : null;
        return (
          <Reveal effect="animated zoomIn" key={step.gallery_title} style={{backgroundImage: `url(${step.gallery_image.url})`}}>
            <div className="subprogram-body-gallery">
              <div className="overlay-bg"></div>
              <div className="padding"><div></div></div>
              <div className="subprogram-body-gallery-inner">
                  <div className="subprogram-body-gallery-title">{galleryTitle?.text}</div>
                  <div className="subprogram-body-gallery-desc">{ RichText.render(step.gallery_description) }</div>
              </div>
            </div>
          </Reveal>
        )
      });

      return (
        <div className="subprogram" id={title?.toLowerCase().replace(/\s+/g, '-')} key={title}>
            <div className="subprogram-intro-container" style={{backgroundColor: themeColor}}>
                <div className="subprogram-intro-inner">
                    <Reveal effect={`animated ${animatedFirst}`}>
                      <div className="subprogram-intro text">
                        <h1>{title}</h1>
                        <div>{RichText.render(description)}</div>
                      </div>
                    </Reveal>
                    <Reveal effect={`animated ${animatedSecond}`}>
                      <div className="subprogram-intro gallery">
                        {gallery && (
                          <Slider {...gallerySettings}>
                            {gallery.map(image => {
                              return <div className="subprogram-image" style={{backgroundImage: `url("${image.gallery_image?.url}")`}} key={image.gallery_image?.url}>
                                <img src={image.gallery_image?.url} />
                              </div>
                            })}
                          </Slider>
                        )}
                      </div>
                    </Reveal>
                </div>
            </div>

            {video && 
              <div className="subprogram-video-container">
                <div className="embed-container">
                  <iframe src={`https://player.vimeo.com/video/${video}?background=1&autoplay=1&muted=1&loop=1&byline=0&title=0&controls=0&playsinline=1`} />
                </div>
              </div>
            }

            <div className="subprogram-body">
              {steps && steps.length && (
                <div className="subprogram-steps-inner">
                  <div className="home-section-title-container">
                    <h1 className="home-section-title how-works">How it works</h1>
                    <span />
                  </div>
                  <div className="subprogram-steps-inner-steps">
                    {renderSteps()}
                  </div>
                </div>
              )}
              {step_gallery && step_gallery.length && renderStepGallery()}
            </div>
            {html && <div className="subprogram-body" dangerouslySetInnerHTML={{ __html: html }} />}

        </div>
      );
    };

    return (
      <div className="program-subprograms-container">
          {fields.map((sub, i) => renderSubprogram(sub, i))}
      </div>
    );
};

export default SubProgram;
